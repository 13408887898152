//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { STable, XCard } from '@/components'
// eslint-disable-next-line no-unused-vars
import { sysNoticeReceived } from '@/api/modular/system/noticeReceivedManage'
import detailForm from './detailForm'
export default {
  components: {
    XCard,
    STable,
    detailForm
  },
  data () {
    return {
      // 高級搜索 展開/關閉
      advanced: false,
      // 查詢參數
      queryParam: {},
      // 表頭
      columns: [
        {
          title: '標題',
          dataIndex: 'title'
        },
        {
          title: '類型',
          dataIndex: 'type',
          scopedSlots: { customRender: 'type' }
        },
        {
          title: '狀態',
          dataIndex: 'status',
          scopedSlots: { customRender: 'status' }
        }
      ],
      // 加載數據方法 必須為 Promise 對象
      loadData: parameter => {
        return sysNoticeReceived(Object.assign(parameter, this.queryParam)).then((res) => {
          return res.data
        })
      },
      selectedRowKeys: [],
      selectedRows: [],
      statusDictTypeDropDown: [], // 0草稿 1發布 2撤回 3刪除
      typeDictTypeDropDown: []// 0通知 1公告
    }
  },
  created () {
    this.sysDictTypeDropDown()// 先註釋
    if (this.hasPerm('sysNotice:received')) {
      this.columns.push({
        title: '操作',
        width: '200px',
        dataIndex: 'action',
        scopedSlots: { customRender: 'action' }
      })
    }
  },
  methods: {
    /**
     * 獲取字典數據
     */
    sysDictTypeDropDown () {
      this.statusDictTypeDropDown = this.$options.filters['dictData']('notice_status')
      this.typeDictTypeDropDown = this.$options.filters['dictData']('notice_type')
    },
    handleOk () {
      this.$refs.table.refresh()
    },
    onSelectChange (selectedRowKeys, selectedRows) {
      this.selectedRowKeys = selectedRowKeys
      this.selectedRows = selectedRows
    }
  }
}
